<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getData"
  :isLoading="isLoading",
  :actions="actions"
)
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
export default {
  data () {
    return {
      headers: [
        { value: 'created_at',
          text: this.$t('tableHeaders.created_at'),
          sortable: true },
        { value: 'statement_service_record',
          text: this.$t('tableHeaders.statement_service_record'),
          sortable: false },
        { value: 'sailor.id',
          text: this.$t('tableHeaders.sailor_id'),
          sortable: false },
        { value: 'sailorFullName',
          text: this.$t('tableHeaders.sailor_full_name'),
          sortable: false },
        { value: 'crewing_company',
          text: this.$t('tableHeaders.crewingName'),
          sortable: false },
        { value: 'manager_full_name',
          text: this.$t('tableHeaders.manager_full_name'),
          sortable: false },
        { value: 'status_document',
          text: this.$t('tableHeaders.status_document'),
          sortable: true },
        { value: 'event',
          text: this.$i18n.t('actions'),
          sortable: false,
          width: '24px' }
      ],
      actions: [
        { id: 1,
          tooltip: 'tooltip.go',
          to: (item) => ({ name: 'statements-service-record-book-isc', params: { documentID: item.id } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          isView: true }

      ],
      isLoading: true
    }
  },
  computed: {
    ...mapState({
      items: state => state.statement.statementServiceRecordBook
    })
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'statementSRBISC', access: checkAccess('tab-statementServiceRecordBookISC') })
  },
  methods: {
    ...mapActions(['getStatementRecordBookISC']),
    async getData (filter) {
      this.isLoading = true
      await this.getStatementRecordBookISC(filter)
      this.isLoading = false
    }
  }
}
</script>
